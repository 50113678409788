import styled from "@emotion/styled";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  LinearProgress,
  OutlinedInput,
  Radio,
  Select,
  Slider,
  Switch,
  Tab,
  TableCell,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";

export const RealDivider = styled(Divider)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
}));

export const TeldioTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#A0AD39",
  },
});

export const TeldioTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    color: "grey",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "grey",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#A0AD39",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#A0AD39",
    },
  })
);

export const ColorButton = styled(Button)(({ theme }) => ({
  margin: "20px",
  borderRadius: "50px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  color: "white",
}));

export const TeldioSlider = styled(Slider)(({ theme }) => ({
  color: "#A0AD39",
}));

export const TeldioSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "200ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#A0AD39",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#C8CE80",
      },
    },
    "&.Mui-disabled": {
      color: "#EDEDED",
      "& + .MuiSwitch-track": {
        backgroundColor: "#A0AD39",
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        opacity: 0.5,
        backgroundColor: "#C8CE80",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    backgroundColor: "#D1D1CF",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#AFAFAE",
    opacity: 1,
  },
}));

export const SquaredButton = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  backgroundColor: "#adadad",
  "&:hover": {
    backgroundColor: "#c6c6c6",
  },
  color: "white",
}));

export const SquaredButtonGreen = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  color: "white",
}));

export const RoundedTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "30px",
    },
  },
});

export const SquaredLeftFormControl = styled(FormControl)({
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A0AD39",
      borderRadius: "0px 20px 20px 0px",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39",
    },
  },
  "& .MuiInputBase-input": {
    color: "grey",
  },
});

export const SquaredLeftSelect = styled(Select)({
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A0AD39",
      borderRadius: "0px 20px 20px 0px",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39",
    },
  },
  "& .MuiInputBase-input": {
    color: "grey",
  },
});
export const TeldioTextField = styled(({ placeholderColor, ...other }) => (
  <TextField {...other} />
))(({ theme, placeholderColor }) => ({
  "& .MuiInputLabel-root": {
    color: placeholderColor || "black", // Set label text color to black
  },
  "& .MuiInputLabel-shrink": {
    color: "black", // Set shrunk label text color to black
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent", // Set border color
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39", // Set border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39", // Set border color when focused
    },
  },
  "& .MuiInputBase-input": {
    color: placeholderColor || "black", // Set input text color to black
    "&::placeholder": {
      color: placeholderColor || "darkgrey", // Set placeholder text color
      opacity: 0.5, // Set the opacity of the placeholder text
    },
  },
}));

export const SquaredRightTextField = styled(TextField)({
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "black", // Set label text color to white
  },
  "& .MuiInputLabel-root": {
    // This targets the label directly
    color: "black", // Set label text color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A0AD39", // Border remains white on hover
      borderRadius: "20px 0px 0px 20px",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39", // Border remains white on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39", // Border remains white on hover
    },
  },
  "& .MuiInputBase-input": {
    // This targets the input text
    color: "grey", // Set input text color to white
  },
});

// // Styled Select component
// export const TeldioSelect = styled(({ placeholderColor, ...other }) => (
//   <Select {...other} />
// ))(({ theme, placeholderColor }) => ({
export const TeldioSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent", // Set border color
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39", // Set border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39", // Set border color when focused
    },
  },
  "& .MuiSelect-select": {
    // color: placeholderColor || "black", // Set input text color to black
    "&::placeholder": {
      color: "darkgrey", // Set placeholder text color to dark grey
      opacity: 1, // Ensure placeholder text color is visible
    },
  },
});

export const TeldioSelectBase = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent", // Set border color
      borderRadius: "2px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39", // Set border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39", // Set border color when focused
    },
  },
  "& .MuiSelect-select": {
    // color: placeholderColor || "black", // Set input text color to black
    "&::placeholder": {
      color: "darkgrey", // Set placeholder text color to dark grey
      opacity: 1, // Ensure placeholder text color is visible
    },
  },
});

// Styled OutlinedInput component
export const TeldioOutlinedInput = styled(({ placeholderColor, ...other }) => (
  <OutlinedInput {...other} />
))(({ theme, placeholderColor }) => ({
  // export const TeldioOutlinedInput = styled(OutlinedInput)({
  "&.MuiOutlinedInput-root": {
    borderColor: "transparent", // Set border color
    borderRadius: "15px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  },
  "&:hover fieldset": {
    borderColor: "#A0AD39", // Set border color on hover
  },
  "&.Mui-focused fieldset": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
}));

export const TeldioOutlinedInputBase = styled(
  ({ placeholderColor, ...other }) => <OutlinedInput {...other} />
)(({ theme, placeholderColor }) => ({
  // export const TeldioOutlinedInput = styled(OutlinedInput)({
  "&.MuiOutlinedInput-root": {
    borderColor: "transparent", // Set border color
    borderRadius: "5px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  },
  "&:hover fieldset": {
    borderColor: "#A0AD39", // Set border color on hover
  },
  "&.Mui-focused fieldset": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0AD39", // Set border color when focused
  },
}));

export const RoundedTextFieldVisible = styled(TextField)({
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "white", // Set label text color to white
  },
  "& .MuiInputLabel-root": {
    // This targets the label directly
    color: "white", // Set label text color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Always white border
      borderRadius: "20px",
    },
    "&:hover fieldset": {
      borderColor: "white", // Border remains white on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border remains white when focused
    },
  },
  "& .MuiInputBase-input": {
    // This targets the input text
    color: "white", // Set input text color to white
  },
});

export const RoundedCard = styled(Card)({
  paddingBottom: "30px",
  borderRadius: "20px",
  backgroundColor: "white",
});

export const BigButton = styled(Button)(({ theme }) => ({
  width: "90%",
  height: "40px",
  margin: "20px",
  borderRadius: "50px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  fontSize: "17px",
  color: "white",
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  color: "#686868",
  fontWeight: "bold",
  fontFamily: "fonts/Oxygen-Light, Segoe UI, Roboto",
  textTransform: "none",
  fontSize: "15px",
  marginTop: "20px",
}));

export const CenterTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  fontSize: "18px",
}));

export const TeldioLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#E3E7BE",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#A0AD39",
  },
}));

export const TaskModalButton = styled(Button)(() => ({
  textTransform: "none",
  justifyContent: "left",
  backgroundColor: "#E3E6EA",
  color: "#182A4D",
  marginBottom: "10px",
}));

export const TaskModalButtonBase = styled(Button)(() => ({
  textTransform: "none",
  justifyContent: "left",
  marginBottom: "10px",
}));

export const SquaredButtonGray = styled(Button)(() => ({
  textTransform: "none",
  backgroundColor: "#E3E6EA",
  color: "#182A4D",
  marginBottom: "10px",
}));

export const SquaredButtonBase = styled(Button)(() => ({
  textTransform: "none",
}));

export const ActiveBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px 0.2`,
  },
}));

export const TeldioCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#A0AD39",
  "&.Mui-checked": {
    color: "#A0AD39",
  },
}));

export const TeldioRadioButton = styled(Radio)(({ theme }) => ({
  color: "#A0AD39",
  "&.Mui-checked": {
    color: "#A0AD39",
  },
}));

export const ReactionTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    borderRadius: "8px",
    opacity: 1,
    backgroundColor: "white", // Ensure background is not transparent
    color: "black", // Ensure text color is visible
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a subtle shadow for better visibility
  },
}));

export const TeldioBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#A0AD39",
    marginRight: "3px",
  },
}));

export const getAddCommentTextField = (fontColor, backgroundColor) => ({
  control: {
    fontSize: 14,

    width: "100%",
    fontWeight: "normal",
    borderRadius: "15px",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  "&multiLine": {
    control: {
      padding: 9,

      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      "&:focus-visible": {
        outline: "none",
      },
    },
    input: {
      padding: 9,
      color: fontColor || "black",
      border: "1px solid silver",
      "&:focus-visible": {
        outline: "none",
      },
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      // "&::placeholder": {
      //   color: fontColor || "black",
      //   opacity: 1,
      // },
    },
  },
  suggestions: {
    backgroundColor: backgroundColor,
    padding: "2px",
    borderRadius: "5px",
  },
});

export const getEditCommentTextField = (fontColor, backgroundColor) => ({
  control: {
    fontSize: 14,
    fontWeight: "normal",
    borderRadius: "15px",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  "&multiLine": {
    control: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      border: "1px solid transparent",
      "&:focus-visible": {
        outline: "none",
      },
    },

    input: {
      padding: 9,
      color: fontColor || "black",

      border: "1px solid silver",
      "&:focus-visible": {
        outline: "none",
      },
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
  },
  suggestions: {
    list: {
      backgroundColor: backgroundColor,
      padding: "2px",
      borderRadius: "5px",
    },
  },
});

export const getReplyCommentTextField = (fontColor) => ({
  control: {
    fontSize: 14,
    width: "340px",
    fontWeight: "normal",
    borderRadius: "15px",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  "&multiLine": {
    control: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      border: "1px solid transparent",
      "&:focus-visible": {
        outline: "none",
      },
    },
    highlighter: {
      padding: 9,
      borderRadius: "15px",
      borderColor: "#A0AD39",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
    input: {
      padding: 9,
      color: fontColor || "black",
      border: "1px solid silver",
      "&:focus-visible": {
        outline: "none",
      },
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      "&::placeholder": {
        color: fontColor || "black",
        opacity: 1,
      },
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      bottom: "100%",
      top: "auto",
      marginBottom: "5px",
    },
  },
});

import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  SquaredButtonGreen,
  TeldioTextField,
} from "../../MUI_Components/Components";
import { sendEmail } from "../../Helper/utils";
import { useSettings } from "../../Contexts/SettingsContext";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  get,
} from "firebase/database";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";

const InviteSidebar = ({ open, onClose, boardId, currentUser, board }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [acceptedInvites, setAcceptedInvites] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [deletingInvite, setDeletingInvite] = useState(null);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [searchingEmails, setSearchingEmails] = useState(false);
  const functions = getFunctions();
  const db = getDatabase();
  const { settings } = useSettings();
  const themeDetails = useThemeContext();

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchEmailSuggestions = async (searchText) => {
    setSearchingEmails(true);
    const usersRef = ref(db, "users");
    const usersQuery = query(
      usersRef,
      orderByChild("email"),
      startAt(searchText),
      endAt(searchText + "\uf8ff")
    );
    try {
      const snapshot = await get(usersQuery);
      const suggestions = [];
      snapshot.forEach((childSnapshot) => {
        suggestions.push(childSnapshot.val());
      });
      setEmailSuggestions(suggestions.map((s) => s.email));
    } catch (error) {
      console.error("Error fetching email suggestions:", error);
    } finally {
      setSearchingEmails(false);
    }
  };

  const debouncedFetchEmailSuggestions = useCallback(
    debounce(fetchEmailSuggestions, 500),
    []
  );

  useEffect(() => {
    if (email.length > 0) {
      debouncedFetchEmailSuggestions(email);
    } else {
      setEmailSuggestions([]);
    }
  }, [email, debouncedFetchEmailSuggestions]);

  const fetchInvites = async () => {
    const getInvitesSent = httpsCallable(functions, "getInvitesSent");
    try {
      const result = await getInvitesSent({ senderUid: currentUser.uid });
      const filteredResultsAccepted = result?.data.accepted.filter(
        (item) => item.boardId === boardId
      );
      const filteredResultsPending = result?.data.pending.filter(
        (item) => item.boardId === boardId
      );

      setPendingInvites(filteredResultsPending);
      setAcceptedInvites(filteredResultsAccepted);
      setLoadingCollaborators(false);
    } catch (error) {
      console.error("Error fetching invites:", error);
    }
  };

  useEffect(() => {
    if (currentUser && open) {
      fetchInvites();
      const intervalId = setInterval(fetchInvites, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [currentUser, functions, boardId, open]);

  const handleDeleteInvite = async (invite) => {
    setDeletingInvite(invite.inviteeId);
    const deleteInvite = httpsCallable(functions, "deleteInvite");
    try {
      await deleteInvite({
        inviteeId: invite.inviteeId,
        boardId: invite.boardId,
      });
      setSnackbarMessage("Invite deleted successfully.");
      setSnackbarSeverity("success");
      fetchInvites();
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setDeletingInvite(null);
    }
  };

  const handleRemoveUser = async (invite) => {
    const removeUserFromBoard = httpsCallable(functions, "removeUserFromBoard");
    try {
      await removeUserFromBoard({
        boardId,
        userUid: invite.inviteeId,
        ownerUID: currentUser.uid,
      });
      setSnackbarMessage("User removed from the board successfully.");
      setSnackbarSeverity("success");
      fetchInvites();
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleInvite = async () => {
    setLoading(true);
    setSnackbarMessage("");
    const sendInvite = httpsCallable(functions, "sendInvite");
    try {
      const result = await sendInvite({
        email,
        boardId,
        senderUid: currentUser.uid,
        boardName: board.title,
        senderEmail: currentUser.email,
      });
      sendEmail(email, "board invite", {
        boardName: board.title,
        fromEmail: currentUser.email,
      });
      setSnackbarMessage(result.data.message);
      setSnackbarSeverity("success");
      setEmail("");
      fetchInvites();
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box
          sx={{
            width: 300,
            padding: 2,
            backgroundColor:
              themeDetails[settings.cardColor]?.primary || "white",
            color: themeDetails[settings.cardColor]?.font || "black",
            height: "100%",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            width="100%"
            sx={{ marginBottom: 1 }}
          >
            <Typography
              variant="h6"
              style={{ width: "100%", textAlign: "center" }}
            >
              Collaborators
            </Typography>
            <IconButton
              style={{
                position: "absolute",
                right: 0,
                color: themeDetails[settings.cardColor]?.font || "black",
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{
              backgroundColor:
                themeDetails[settings.cardColor]?.font || "black",
              opacity: "0.5",
            }}
          />
          <Box sx={{ marginTop: 2 }}>
            <Autocomplete
              options={emailSuggestions}
              freeSolo
              inputValue={email}
              onInputChange={(event, newInputValue) => {
                setEmail(newInputValue);
              }}
              renderInput={(params) => (
                <>
                  <TeldioTextField
                    {...params}
                    placeholder="Collaborator"
                    placeholderColor={
                      themeDetails[settings.cardColor]?.font || "darkgrey"
                    }
                    fullWidth
                    sx={{ marginTop: 2 }}
                    autoComplete="off"
                    name="disable-autofill"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                </>
              )}
            />

            <SquaredButtonGreen
              variant="contained"
              onClick={handleInvite}
              disabled={loading}
              sx={{ marginTop: 2 }}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : "Send Invite"}
            </SquaredButtonGreen>
          </Box>
          <Divider
            sx={{
              marginTop: 2,
              backgroundColor:
                themeDetails[settings.cardColor]?.font || "black",
              opacity: "0.5",
            }}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="h6">Pending Invites</Typography>
            <List>
              {pendingInvites.map((invite) => (
                <ListItem
                  key={`${invite.inviteeId}-${invite.boardId}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteInvite(invite)}
                    >
                      {deletingInvite === invite.inviteeId ? (
                        <CircularProgress size={24} />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${invite.email}`} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider
            sx={{
              marginTop: 2,
              backgroundColor:
                themeDetails[settings.cardColor]?.font || "black",
              opacity: "0.5",
            }}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="h6">Collaborators</Typography>
            {loadingCollaborators ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress sx={{ color: "#A0AD39" }} />
              </div>
            ) : (
              <List>
                {acceptedInvites.map((invite) => (
                  <ListItem
                    key={`${invite.inviteeId}-${invite.boardId}`}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() => handleRemoveUser(invite)}
                      >
                        <CloseIcon color="error" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${invite.email}`} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InviteSidebar;

import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  CircularProgress,
  Alert,
  Avatar,
} from "@mui/material";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  RealDivider,
  SquaredButton,
  SquaredButtonGreen,
} from "../../MUI_Components/Components";
import HomeIcon from "@mui/icons-material/Home";
import FileUploadViewer from "../FileUploadViewer/FileUploadViewer";
import Modal from "../FileUploader/modal/Modal";
import { useAuth } from "../../firebase/contexts/AuthContext";
import PageviewIcon from "@mui/icons-material/Pageview";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DashboardPage from "./DashboardPage";
import RoomIcon from "@mui/icons-material/Room";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CalculateIcon from "@mui/icons-material/Calculate";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import KeyIcon from "@mui/icons-material/Key";
import { getDB } from "../../firebase/firebase";
import { get, off, onValue, ref, set } from "firebase/database";
import AdminConsole from "../AdminConsole/AdminConsole";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DemoPage from "../DemoPage/DemoPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ResetPasswordModal from "./ResetPasswordModal";
import Wiki from "../Wiki/Wiki";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import IFrameView from "../IFrameView/IFrameView";
import WikiManager from "../WikiManager/WikiManager";
import DrawIcon from "@mui/icons-material/Draw";
import Clipboard from "../Clipboard/Clipboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { getAuth, getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import LaptopIcon from "@mui/icons-material/Laptop";
import TEGViewer from "../TEGViewer/TEGViewer";
import { getRoleReadable, setCustomThemes } from "../../Helper/utils";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Boards from "../Boards/Boards";
import UserProfileModal from "./UserProfileModal";
import Board from "../Boards/Board";
import TaskModal from "../Boards/TaskModal";
import CloudIcon from "@mui/icons-material/Cloud";
import teldioIcon from "../../Icons/favicon.ico";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import BoardsUpdate from "../BoardsUpdate/BoardsUpdate";
import NewUserPassword from "./NewUserPassword";
import { useSettings } from "../../Contexts/SettingsContext";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";

const roleHierarchy = {
  default: [],
  avtec: ["default"],
  teldioSales: ["avtec"],
  teldioSupport: ["teldioSales"],
  admin: ["teldioSupport"],
  dev: ["admin"],
};

const toolsByRole = {
  default: [
    "Dashboard",
    "GPS Configurator",
    "Beacon Calculator",
    "Wiki",
    "Clipboard",
    "Boards",
    "NOAA",
  ],
  avtec: ["Trufleet Plus Demos"],
  teldioSales: [],
  teldioSupport: [
    "FileUpload",
    "FileUpload Viewer",
    "Config Merger",
    "Trufleet Adder",
    "TEG Licenser",
  ],
  admin: ["Admin Console"],
  dev: ["Wiki Manager", "TEG Viewer", "Boards Update"],
};

const getAllToolsForRole = (role) => {
  let tools = new Set(toolsByRole[role] || []);
  const rolesToCheck = roleHierarchy[role] || [];
  rolesToCheck.forEach((inheritedRole) => {
    const inheritedTools = getAllToolsForRole(inheritedRole);
    inheritedTools.forEach((tool) => tools.add(tool));
  });
  return Array.from(tools);
};

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { currentUser, logout } = useAuth();
  const [role, setRole] = useState(null);
  const [isNew, setIsNew] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState({ link: "", name: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const { settings } = useSettings();
  const themeDetails = useThemeContext();

  useEffect(() => {
    console.log("this", localStorage.getItem("redirectPath"));
    if (!currentUser) {
      localStorage.setItem("redirectPath", location.pathname);
    }
  }, [currentUser, location]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchUserRole = async (user) => {
    try {
      const tokenResult = await getIdTokenResult(user);

      // Set the user's role from custom claims
      setRole(tokenResult?.claims?.role || "");

      // Set 'newUser' based on the custom claim
      setNewUser(tokenResult?.claims?.newUser || false);

      console.log("tokenResult", tokenResult);

      // Optionally, update 'isNew' if it's used elsewhere
      setIsNew(tokenResult?.claims?.newUser || false);
    } catch (error) {
      setError("Error fetching user role");
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserRole(user);
      } else {
        setRole("No user signed in");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const index = getPathIndex(path);
    console.log("setting", index);
    setSelectedIndex(index);
  }, [location]);

  const getPathIndex = (path) => {
    console.log(path);
    switch (path) {
      case "/":
        return 1;
      case "/file-upload-viewer":
        return 2;
      case "/admin-console":
        return 16;
      case "/trufleet-plus-demos":
        return 4;
      case "/gps-configurator":
        return 5;
      case "/beacon-calculator":
        return 7;
      case "/wiki":
        return 6;
      case "/clipboard":
        return 9;
      case "/boards":
        return 11;
      case "/file-upload":
        return 12;
      case "/config-merger":
        return 13;
      case "/trufleet-adder":
        return 14;
      case "/wiki-manager":
        return 15;
      case "/teg-viewer":
        return 17;
      case "/NOAA":
        return 18;
      case "/boards-update":
        return 19;
      // Add other cases for different paths as needed
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const db = getDB();
      const recentRef = ref(db, `/uid/${currentUser.uid}/role`);
      const unsubscribe = onValue(
        recentRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setRole(snapshot.val());
          } else {
            console.log("No data available");
          }
          setLoading(false);
        },
        (error) => {
          console.error("Failed to read data:", error);
          setError(error.message);
          setLoading(false);
        }
      );

      return () => off(recentRef, "value", unsubscribe);
    }
  }, [currentUser]);

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    navigate(path);
  };

  function handleToolSelect(tool, index, path, link, name) {
    if (tool === "TEG Licenser") {
      window.open(link, "_blank");
      return;
    }
    if (tool !== "boards") {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = teldioIcon;
    }
    clickTool(tool);
    document.title = `${tool} | Teldio Tools`;
    handleListItemClick(index, path);
    setSelectedPage({ link, name });
  }

  function clickTool(toolId) {
    const userId = currentUser.uid;
    if (!userId) {
      console.error("User ID is undefined");
      return;
    }

    const db = getDB();
    if (!db) {
      console.error("Database connection failed");
      return;
    }

    const recentRef = ref(db, `/uid/${userId}/settings/recent`);

    get(recentRef)
      .then((snapshot) => {
        let recentTools = snapshot.exists() ? snapshot.val() : [];

        const index = recentTools.indexOf(toolId);
        if (index > -1) {
          recentTools.splice(index, 1);
        }

        recentTools.unshift(toolId);

        recentTools = recentTools.slice(0, 5);

        set(recentRef, recentTools)
          .then(() => {
            console.log("Update successful");
          })
          .catch((error) => {
            console.error("Failed to set recent tools:", error);
          });
      })
      .catch((error) => {
        console.error("Failed to update recent tools:", error);
      });
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const tools = role ? getAllToolsForRole(role) : [];

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        width: 250,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        marginTop: "15%",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "20px",
      }}
    >
      <List>
        <Avatar
          src={currentUser.photoURL}
          alt="Profile Picture"
          sx={{ width: 56, height: 56, marginBottom: "20px" }}
        />
        <ListItemText sx={{ marginBottom: "40px" }}>
          <Typography variant="h4">
            Hello{" "}
            {currentUser.displayName
              ? currentUser.displayName
              : currentUser.email.split("@")[0]}
            !
          </Typography>
          <Typography variant="subtitle1">{getRoleReadable(role)}</Typography>
        </ListItemText>
        {tools.includes("Dashboard") && (
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1, "/")}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {tools.includes("Trufleet Plus Demos") && (
          <ListItemButton
            selected={selectedIndex === 4}
            onClick={() =>
              handleToolSelect("Trufleet Plus Demos", 4, "/trufleet-plus-demos")
            }
          >
            <ListItemIcon>
              <PlayCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Trufleet Plus Demos" />
          </ListItemButton>
        )}
        {tools.includes("GPS Configurator") && (
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={() =>
              handleToolSelect(
                "GPS Configurator",
                7,
                "/gps-configurator",
                "https://gpsconfigurator.teldio.com?tools=true",
                "GPS Configurator"
              )
            }
          >
            <ListItemIcon>
              <RoomIcon />
            </ListItemIcon>
            <ListItemText primary="GPS Configurator" />
          </ListItemButton>
        )}
        {tools.includes("Beacon Calculator") && (
          <ListItemButton
            selected={selectedIndex === 7}
            onClick={() =>
              handleToolSelect(
                "Beacon Calculator",
                7,
                "/beacon-calculator",
                "https://beaconcalculator.teldio.com?tools=true",
                "Beacon Calculator"
              )
            }
          >
            <ListItemIcon>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText primary="Beacon Calculator" />
          </ListItemButton>
        )}
        {tools.includes("Wiki") && (
          <ListItemButton
            selected={selectedIndex === 6}
            onClick={() => handleToolSelect("Wiki", 6, "/wiki")}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Wiki" />
          </ListItemButton>
        )}
        {tools.includes("Clipboard") && (
          <ListItemButton
            selected={selectedIndex === 9}
            onClick={() => handleToolSelect("Clipboard", 9, "/clipboard")}
          >
            <ListItemIcon>
              <ContentPasteIcon />
            </ListItemIcon>
            <ListItemText primary="Clipboard" />
          </ListItemButton>
        )}
        {tools.includes("Boards") && (
          <ListItemButton
            selected={selectedIndex === 11}
            onClick={() => handleToolSelect("Boards", 11, "/boards")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Boards" />
          </ListItemButton>
        )}
        {tools.includes("NOAA") && (
          <>
            <ListItemButton
              selected={selectedIndex === 18}
              onClick={() => {
                let url = `https://noaa.teldio.com?tools=true`;
                handleToolSelect("NOAA", 18, "/NOAA", url, "NOAA");
              }}
            >
              <ListItemIcon>
                <CloudIcon />
              </ListItemIcon>
              <ListItemText primary="NOAA Locator" />
            </ListItemButton>
          </>
        )}
        {tools.includes("FileUpload") && (
          <>
            <RealDivider />
            <Typography variant="h5">Teldio</Typography>
            <ListItemButton
              selected={selectedIndex === 12}
              onClick={() => {
                let url = `https://fileupload.teldio.com?tools=true&email=${currentUser.email}`;
                console.log("email", currentUser.email);
                console.log("url", url);
                handleToolSelect(
                  "FileUpload",
                  12,
                  "/file-upload",
                  url,
                  "File Upload"
                );
              }}
            >
              <ListItemIcon>
                <DriveFolderUploadIcon />
              </ListItemIcon>
              <ListItemText primary="File Upload" />
            </ListItemButton>
          </>
        )}
        {tools.includes("FileUpload Viewer") && (
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={() =>
              handleToolSelect("FileUpload Viewer", 2, "/file-upload-viewer")
            }
          >
            <ListItemIcon>
              <PageviewIcon />
            </ListItemIcon>
            <ListItemText primary="File Upload Viewer" />
          </ListItemButton>
        )}
        {tools.includes("Config Merger") && (
          <ListItemButton
            selected={selectedIndex === 13}
            onClick={() =>
              handleToolSelect(
                "Config Merger",
                13,
                "/config-merger",
                "https://configmerger.teldio.com?tools=true",
                "Config Merger"
              )
            }
          >
            <ListItemIcon>
              <CallMergeIcon />
            </ListItemIcon>
            <ListItemText primary="Config Merger" />
          </ListItemButton>
        )}
        {tools.includes("Trufleet Adder") && (
          <ListItemButton
            selected={selectedIndex === 14}
            onClick={() =>
              handleToolSelect(
                "Trufleet Adder",
                14,
                "/trufleet-adder",
                "https://adder.teldio.com?tools=true",
                "Trufleet Adder"
              )
            }
          >
            <ListItemIcon>
              <AddLocationIcon />
            </ListItemIcon>
            <ListItemText primary="Trufleet Adder" />
          </ListItemButton>
        )}
        {tools.includes("TEG Licenser") && (
          <ListItemButton
            onClick={() =>
              handleToolSelect(
                "TEG Licenser",
                7,
                "",
                "http://licenser.teldio.com/",
                "TEG Licenser"
              )
            }
          >
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText primary="TEG Licenser*" />
          </ListItemButton>
        )}
        {tools.includes("Wiki Manager") && (
          <ListItemButton
            selected={selectedIndex === 15}
            onClick={() => handleToolSelect("Wiki Manager", 8, "/wiki-manager")}
          >
            <ListItemIcon>
              <DrawIcon />
            </ListItemIcon>
            <ListItemText primary="Wiki Manager" />
          </ListItemButton>
        )}
        {tools.includes("Admin Console") && (
          <>
            <RealDivider />
            <Typography variant="h5">Admin</Typography>
            <ListItemButton
              selected={selectedIndex === 16}
              onClick={() =>
                handleToolSelect("Admin Console", 16, "/admin-console")
              }
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin Console" />
            </ListItemButton>
          </>
        )}
        {tools.includes("TEG Viewer") && (
          <>
            <RealDivider />
            <Typography variant="h5">Dev</Typography>
            <ListItemButton
              selected={selectedIndex === 17}
              onClick={() => handleToolSelect("TEG Viewer", 17, "/teg-viewer")}
            >
              <ListItemIcon>
                <LaptopIcon />
              </ListItemIcon>
              <ListItemText primary="TEG Viewer" />
            </ListItemButton>
          </>
        )}
        {tools.includes("Boards Update") && (
          <>
            <ListItemButton
              selected={selectedIndex === 19}
              onClick={() =>
                handleToolSelect("Boards Update", 19, "/boards-update")
              }
            >
              <ListItemIcon>
                <DashboardCustomizeIcon />
              </ListItemIcon>
              <ListItemText primary="Boards Update" />
            </ListItemButton>
          </>
        )}
      </List>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SquaredButton
          variant="contained"
          startIcon={<AccountCircleIcon />}
          onClick={() => setOpen(true)}
          sx={{ marginBottom: "20px" }}
        >
          Account Settings
        </SquaredButton>
        <SquaredButtonGreen
          variant="contained"
          startIcon={<LogoutIcon />}
          onClick={logout}
        >
          Logout
        </SquaredButtonGreen>
        {(role === "admin" || role === "dev") && (
          <Typography
            variant="subtitle2"
            sx={{ color: "grey", marginTop: "10px" }}
          >
            *Must be on Teldio internal network
          </Typography>
        )}
      </div>
    </div>
  );

  if (loading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
      </div>
    );
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <>
      <div
        style={{
          width: "100dvw",
          height: "50px",
          backgroundColor: "#424242",
          display: "flex",
          alignItems: "center",
          fontFamily: "fonts/Oxygen-Light",
          position: "relative",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ marginLeft: "10px", color: "white" }}
          onClick={() => setIsDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          sx={{
            flexGrow: 1,
            color: "white",
            fontFamily: "inherit",
            textAlign: "center",
          }}
          variant="h5"
        >
          Teldio Tools
        </Typography>
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo.png`}
          alt="teldio logo"
          selected={selectedIndex === 1}
          onClick={() => {
            selectedIndex === 1
              ? setIsDrawerOpen(true)
              : handleListItemClick(1, "/dashboard");
          }}
          style={{
            height: "35px",
            marginRight: "15px",
            cursor: "pointer",
          }}
        />
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <br />
      </div>
      <div>
        <Routes>
          <Route
            path="/"
            element={<DashboardPage handleToolSelect={handleToolSelect} />}
          />
          {tools.includes("FileUpload Viewer") && (
            <Route path="/file-upload-viewer" element={<FileUploadViewer />} />
          )}
          {tools.includes("Admin Console") && (
            <Route path="/admin-console" element={<AdminConsole />} />
          )}
          {tools.includes("Trufleet Plus Demos") && (
            <Route path="/trufleet-plus-demos" element={<DemoPage />} />
          )}
          {tools.includes("Wiki") && <Route path="/wiki" element={<Wiki />} />}
          {tools.includes("FileUpload") && (
            <Route
              path="/file-upload"
              element={
                <IFrameView
                  link={`https://fileupload.teldio.com?tools=true&email=${currentUser.email}`}
                  name="File Upload"
                />
              }
            />
          )}
          {tools.includes("NOAA") && (
            <Route
              path="/NOAA"
              element={
                <IFrameView
                  link={`https://noaa.teldio.com?tools=true`}
                  name="NOAA"
                />
              }
            />
          )}
          {tools.includes("GPS Configurator") && (
            <Route
              path="/gps-configurator"
              element={
                <IFrameView
                  link="https://gpsconfigurator.teldio.com?tools=true"
                  name="GPS Configurator"
                />
              }
            />
          )}
          {tools.includes("Beacon Calculator") && (
            <Route
              path="/beacon-calculator"
              element={
                <IFrameView
                  link="https://beaconcalculator.teldio.com?tools=true"
                  name="Beacon Calculator"
                />
              }
            />
          )}
          {tools.includes("Config Merger") && (
            <Route
              path="/config-merger"
              element={
                <IFrameView
                  link="https://configmerger.teldio.com?tools=true"
                  name="Config Merger"
                />
              }
            />
          )}
          {tools.includes("Trufleet Adder") && (
            <Route
              path="/trufleet-adder"
              element={
                <IFrameView
                  link="https://adder.teldio.com?tools=true"
                  name="Trufleet Adder"
                />
              }
            />
          )}
          {tools.includes("Wiki Manager") && (
            <Route path="/wiki-manager" element={<WikiManager />} />
          )}
          {tools.includes("Clipboard") && (
            <Route path="/clipboard" element={<Clipboard />} />
          )}
          {tools.includes("TEG Viewer") && (
            <Route path="/teg-viewer" element={<TEGViewer />} />
          )}
          {tools.includes("Boards") && (
            <Route path="/boards" element={<Boards />}>
              <Route path=":boardId" element={<Boards />} />
            </Route>
          )}
          {tools.includes("Boards Update") && (
            <Route path="/boards-update" element={<BoardsUpdate />} />
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <UserProfileModal open={open} handleClose={handleClose} />
      <NewUserPassword
        open={newUser}
        handleClose={() => null}
        fetchUserRole={fetchUserRole}
      />
    </>
  );
};

export default Dashboard;

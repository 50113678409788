import React, { useState, useEffect } from "react";
import { ref, onValue, update, push, set } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  Paper,
  Typography,
  Modal,
  Box,
  Tooltip,
  Avatar,
  Badge,
  AvatarGroup,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TaskModal from "./TaskModal";
import NotesIcon from "@mui/icons-material/Notes";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { getFunctions, httpsCallable } from "firebase/functions";
import { convertToArray, getTextColor, sendEmail } from "../../Helper/utils";
import ArchiveIcon from "@mui/icons-material/Archive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import PollIcon from "@mui/icons-material/Poll";
import { useSettings } from "../../Contexts/SettingsContext";
import ImageIcon from "@mui/icons-material/Image";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";

const Task = ({
  boardId,
  columnId,
  taskId,
  columnName,
  labels,
  boardOwner,
  collaborators,
  openTask,
  handleOpenTaskModal,
  boardTitle,
  showingArchived,
  filteredMembers,
  filteredLabels,
  logToColumn,
  reverseComments,
  setReverseComments,
  taskPath,
  isLinked,
  boardPath,
  realTaskPath,
  ownBoards,
  collaboratedBoards,
  searchBarText,
  activity,
  useGradient,
  heatmapThreshold,
}) => {
  const [task, setTask] = useState(null);
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const [assignedMembers, setAssignedMembers] = useState([]);
  const db = getDB();
  const { settings } = useSettings();
  const [selectedCard, setSelectedCard] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [heatmapColor, setHeatmapColor] = useState();
  const themeDetails = useThemeContext();

  useEffect(() => {
    if (taskPath) {
      const taskRef = ref(db, taskPath);
      const unsubscribe = onValue(taskRef, (snapshot) => {
        const taskData = snapshot.val();

        setTask(taskData);
        setAssignedMembers(taskData?.assigned || []);

        // // Get the current time and subtract 7 days (in milliseconds)
        // const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000 * 100;

        // console.log("one", oneWeekAgo);

        // // Filter audit entries from the last week
        // const recentAuditItems = taskData?.audit
        //   ? Object.values(taskData.audit).filter(
        //       (item) => item.timestamp >= oneWeekAgo
        //     )
        //   : [];

        // console.log("taskAuditL", recentAuditItems);

        // // Update heatmapValues with the count of recent audit items
        // setHeatmapValues((prevHeatmapValues) => ({
        //   ...prevHeatmapValues,
        //   [taskId]: recentAuditItems.length,
        // }));
      });
      return () => {
        unsubscribe();
      };
    }
  }, [taskPath, db, taskId, activity]);

  useEffect(() => {
    const latestItem = convertToArray(task?.audit)
      ?.filter((item) => item?.action !== "Card Moved")
      ?.reduce((latest, current) => {
        return latest?.timestamp > current?.timestamp ? latest : current;
      }, null);

    const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    const thresholdMillis = heatmapThreshold * oneDay; // Convert threshold days to milliseconds

    if (!latestItem) {
      setHeatmapColor("#D2222D"); // No audit trail, set to red
      return;
    }

    const timeSinceLastAudit = Date.now() - latestItem.timestamp;

    // Ensure tasks that haven't loaded don't get marked as red
    if (task.audit === undefined) {
      return;
    }

    let color = "#D2222D"; // Default to red for oldest or non-audit tasks

    if (useGradient) {
      // Gradient mode
      if (timeSinceLastAudit <= oneDay) {
        color = "#238823"; // Green for updates within the same day
      } else if (timeSinceLastAudit <= thresholdMillis) {
        // Scale from green (#238823) to yellow (#FFBF00) to red (#D2222D)
        const percentage =
          (timeSinceLastAudit - oneDay) / (thresholdMillis - oneDay);

        if (percentage <= 0.5) {
          // Blend from green to yellow
          const p = percentage * 2; // Scale the percentage for the green-yellow transition
          const r = Math.round(35 + p * (255 - 35)); // Blend red
          const g = Math.round(136 + p * (191 - 136)); // Blend green
          const b = Math.round(35); // Constant blue
          color = `rgb(${r}, ${g}, ${b})`;
        } else {
          // Blend from yellow to red
          const p = (percentage - 0.5) * 2; // Scale the percentage for the yellow-red transition
          const r = Math.round(255 - p * (255 - 210)); // Blend red
          const g = Math.round(191 - p * (191 - 34)); // Blend green
          const b = Math.round(0 + p * 45); // Blend blue
          color = `rgb(${r}, ${g}, ${b})`;
        }
      }
    } else {
      // Non-gradient mode
      if (timeSinceLastAudit <= thresholdMillis) {
        color = "#238823"; // Green for items within the threshold
      } else {
        color = "#D2222D"; // Red if the item is older than the threshold
      }
    }

    setHeatmapColor(color);
  }, [task?.audit, useGradient, heatmapThreshold]);

  useEffect(() => {
    setNotifications(
      activity.filter(
        (incident) =>
          incident.acknowledged === false && incident.taskId === taskId
      )
    );
  }, [activity]);

  useEffect(() => {
    if (openTask.columnId === columnId && openTask.taskId === taskId) {
      setOpen(true);
    }
  }, [openTask, columnId, taskId]);

  function handleToggleLabelText(e) {
    e.preventDefault();
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const settingsRef = ref(db, userSettingsPath);
    console.log(settings?.showLabelText);
    const newVal = settings?.showLabelText ? !settings.showLabelText : true;
    console.log("new", newVal);
    update(settingsRef, { showLabelText: newVal });
  }

  function handleClearNotifications() {
    if (notifications.length === 0 || !notifications) {
      return;
    }

    notifications.forEach((notification) => {
      const viewersRef = ref(
        db,
        `${boardPath}/activity/${notification.uid}/acknowledged`
      );

      set(viewersRef, {
        acknowledged: true,
      });
    });
  }

  useEffect(() => {
    let pressed = false;
    const activeElement = document.activeElement;
    const isInteractiveElement =
      activeElement.tagName === "INPUT" ||
      activeElement.tagName === "TEXTAREA" ||
      activeElement.getAttribute("contenteditable") === "true";

    function handleKeyDown(e) {
      if (
        e.keyCode === 32 &&
        !pressed &&
        selectedCard?.title === task?.title &&
        task?.title?.length > 0 &&
        task?.type !== "divider" &&
        !isInteractiveElement
      ) {
        e.preventDefault();
        pressed = true;

        // Check if the current user is assigned, if not, assign them
        const currentUserMember = convertToArray(collaborators).find(
          (collaborator) => collaborator.uid === currentUser.uid
        );
        const isAssigned = assignedMembers.some(
          (assigned) => assigned.uid === currentUser.uid
        );

        if (!isAssigned && currentUserMember) {
          handleAssignMember(currentUserMember);
        } else if (isAssigned && currentUserMember) {
          handleUnassignMember(currentUserMember);
        }

        // Prevent multiple rapid presses
        setTimeout(() => {
          pressed = false;
        }, 300);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [collaborators, assignedMembers, currentUser, selectedCard, task]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("task") === taskId) {
      setOpen(true);
    }
  }, [taskId]);

  const logAudit = async (action, details, relativeDetails) => {
    console.log("action", action);
    console.log("details", details);
    const auditRef = ref(db, `${taskPath}/audit`);
    const newAuditEntry = {
      action,
      details,
      timestamp: Date.now(),
      sender: currentUser.displayName || currentUser.email,
      senderUID: currentUser.uid,
      relativeDetails: relativeDetails || null,
    };
    await push(auditRef, newAuditEntry);
  };

  const handleAssignMember = async (member) => {
    if (assignedMembers.some((assigned) => assigned.uid === member.uid)) {
      return;
    }

    const updatedAssignedMembers = [...assignedMembers, member];
    setAssignedMembers(updatedAssignedMembers);

    const taskRef = ref(db, taskPath);
    update(taskRef, { assigned: updatedAssignedMembers });

    logAudit(
      "Member Assigned",
      `${member.displayName || member.email} has been assigned to card ${
        task?.title
      }`,
      `assigned <bold>${member.displayName || member.email}</bold>`
    );

    if (member.uid !== currentUser.uid) {
      sendEmail(member.email, "assigned to card", {
        boardName: boardTitle,
        fromEmail: currentUser.email,
        cardName: task.title,
        boardID: boardId,
        cardID: taskId,
      });

      const functions = getFunctions();
      const addActivity = httpsCallable(functions, "addActivity");

      await addActivity({
        boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
        boardId,
        mentionedUser: member.email,
        displayName: member.displayName || member.email.split("@")[0],
        taskId,
        columnId,
        type: "assign",
        mentionedBy: currentUser.uid,
      });
    }
  };

  const handleUnassignMember = (member) => {
    const updatedAssignedMembers = assignedMembers.filter(
      (assigned) => assigned.uid !== member.uid
    );
    setAssignedMembers(updatedAssignedMembers);

    const taskRef = ref(db, taskPath);
    update(taskRef, { assigned: updatedAssignedMembers });

    logAudit(
      "Member Unassigned",
      `${member.displayName || member.email} has been unassigned from card ${
        task.title
      }`,
      `unassigned ${member.displayName || member.email}`
    );
  };

  if (!task) return null;

  const handleOpen = () => {
    setOpen(true);
    handleClearNotifications();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("task", taskId);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  };

  const handleClose = () => {
    setOpen(false);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("task");
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  };

  const getChecklistCompletion = () => {
    if (!task.checklist) return null;
    const completed = task.checklist.filter((item) => item.completed).length;
    const total = task.checklist.length;
    return `${completed}/${total}`;
  };

  const getChecklistsCompletion = (checklist) => {
    const completedCount =
      checklist?.items?.filter((item) => item.completed).length || 0;
    const totalCount = checklist?.items?.length || 0;

    return `${completedCount}/${totalCount}`;
  };

  const isDescriptionEmpty = (description) => {
    if (!description) {
      return true;
    }

    try {
      // Try to parse it as JSON (Draft.js format)
      const parsedDescription = JSON.parse(description);

      if (!parsedDescription.blocks || parsedDescription.blocks.length === 0) {
        return true;
      }

      return parsedDescription.blocks.every(
        (block) => block.text.trim() === ""
      );
    } catch (error) {
      // If it's not valid JSON, assume it's in HTML format (TipTap)
      const strippedHtml = description.replace(/<\/?[^>]+(>|$)/g, "").trim(); // Strip HTML tags and whitespace
      return strippedHtml === "";
    }
  };

  const shouldDisplayTask = () => {
    const searchTextMatch =
      searchBarText.length === 0 ||
      task?.title?.toLowerCase().includes(searchBarText?.toLowerCase());

    const membersMatch =
      filteredMembers.length === 0 ||
      filteredMembers.some((member) =>
        assignedMembers.some((assigned) => assigned.uid === member)
      );

    const labelsMatch =
      filteredLabels.length === 0 ||
      filteredLabels.some((label) =>
        task?.labels?.some((taskLabelId) => labels[taskLabelId]?.name === label)
      );

    return searchTextMatch && membersMatch && labelsMatch;
  };

  // Function to get combined completed checkboxes
  const getCombinedCompletion = (checklists) => {
    return convertToArray(checklists).reduce((acc, checklist) => {
      if (checklist.items && checklist.items.length > 0) {
        const completed = checklist.items.filter(
          (item) => item.completed
        ).length;
        return acc + completed;
      }
      return acc; // Skip checklists with no items
    }, 0);
  };

  // Function to get the total number of checkboxes
  const getTotalCheckboxes = (checklists) => {
    return convertToArray(checklists).reduce((acc, checklist) => {
      if (checklist.items && checklist.items.length > 0) {
        return acc + checklist.items.length;
      }
      return acc; // Skip checklists with no items
    }, 0);
  };

  if (!shouldDisplayTask()) {
    return null;
  }

  return (
    <>
      {(task?.archived !== true || showingArchived === true) && (
        <>
          <Paper
            onMouseEnter={() => {
              setSelectedCard(task);
            }}
            onMouseLeave={() => {
              setSelectedCard(false);
            }}
            onClick={handleOpen}
            className="task-item"
            sx={{
              borderRadius: "15px",
              borderColor: isLinked
                ? "#107898"
                : themeDetails[settings.cardColor]?.primary || "white",
              mr: "5px",
              opacity: task?.archived ? 0.5 : 1,
              backgroundColor: settings?.showHeatmap
                ? heatmapColor
                : task?.archived
                ? themeDetails[settings.cardColor]?.alt || "lightgray"
                : isLinked
                ? themeDetails[settings.cardColor]?.alt || "#F9F6EE"
                : themeDetails[settings.cardColor]?.primary || "white",
              // backgroundColor: taskColors[taskId],
              position: "relative",
              overflow: "hidden",
              color: settings?.showHeatmap
                ? "black"
                : themeDetails[settings.cardColor]?.font || "black",
            }}
          >
            {task?.cover && task?.cover.startsWith("#") && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: task?.cover.startsWith("#") ? "40px" : "100%",
                  background: task?.cover.startsWith("#")
                    ? task?.cover
                    : `url(${task?.cover}) no-repeat center center`,
                  backgroundSize: "cover",
                  zIndex: 0,
                }}
              />
            )}
            {task?.cover && !task?.cover.startsWith("#") && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${task?.cover}`}
                  alt="cover"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
                padding: task?.cover
                  ? task?.cover.startsWith("#")
                    ? "45px 10px 20px 10px"
                    : "5px 10px 20px 10px"
                  : "5px 10px 20px 10px",
                backgroundColor: task?.cover ? "transparent" : "transparent",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: settings?.showLabelText ? "wrap" : "nowrap",
                  rowGap: "5px",
                }}
              >
                {task?.labels?.map((labelId) => {
                  const label = labels[labelId];
                  if (label?.name === undefined) {
                    return null;
                  }
                  return (
                    <Tooltip
                      title={"Label: " + label?.name}
                      key={labelId}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleLabelText(e);
                      }}
                    >
                      <div
                        style={{
                          width: settings?.showLabelText ? "auto" : "50px",
                          marginRight: "10px",
                          minHeight: "9px",
                          borderRadius: settings?.showLabelText
                            ? "5px"
                            : "20px",
                          backgroundColor: label?.color,
                          padding: settings?.showLabelText ? "4px" : "0px",
                          fontSize: "12px",
                          textAlign: "left",
                          transition: "all 0.3s ease",
                          color: getTextColor(label?.color || "#fff"),
                        }}
                      >
                        {settings?.showLabelText && label?.name}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>

              <Typography sx={{ fontSize: "16px", marginTop: "8px" }}>
                {task.title}
              </Typography>
              {isLinked && (
                <div style={{ position: "absolute", top: 1, right: 4 }}>
                  <Tooltip
                    title={
                      "This card is linked to another from a different board"
                    }
                  >
                    <LinkIcon
                      sx={{
                        color: settings?.showHeatmap ? "black" : "#A0AD39",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "10px",
                    width: "100%",
                    gap: 6,
                  }}
                >
                  {task?.watchers &&
                    Object.keys(task?.watchers).includes(currentUser.uid) && (
                      <Box display="flex" alignItems="center">
                        <VisibilityIcon
                          fontSize="small"
                          sx={{
                            color: settings?.showHeatmap ? "black" : "#A0AD39",
                          }}
                        />
                      </Box>
                    )}
                  {!isDescriptionEmpty(task.description) && (
                    <Box display="flex" alignItems="center">
                      <NotesIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                    </Box>
                  )}
                  {task.checklist && (
                    <Box display="flex" alignItems="center">
                      <CheckBoxIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {getChecklistCompletion()}
                      </Typography>
                    </Box>
                  )}
                  {task?.checklists &&
                    convertToArray(task?.checklists).length > 0 && (
                      <Box display="flex" alignItems="center" sx={{ gap: 0.5 }}>
                        {/* Calculate and display combined checklist completion */}
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ mr: 0.2 }}
                        >
                          {convertToArray(task?.checklists).length > 1 ? (
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <CheckBoxIcon
                                fontSize="small"
                                sx={{
                                  color: settings?.showHeatmap
                                    ? "black"
                                    : "#A0AD39",
                                  position: "relative",
                                  zIndex: 0,
                                  opacity: 0.4,
                                }}
                              />

                              <CheckBoxIcon
                                fontSize="small"
                                sx={{
                                  color: settings?.showHeatmap
                                    ? "black"
                                    : "#A0AD39",
                                  position: "absolute",
                                  left: "2px", // Adjust the offset as needed
                                  top: "2px",
                                  zIndex: 1,
                                  opacity: 1, // Optional: To give a slight faded effect to the background icon
                                }}
                              />
                            </Box>
                          ) : (
                            <CheckBoxIcon
                              fontSize="small"
                              sx={{
                                color: settings?.showHeatmap
                                  ? "black"
                                  : "#A0AD39",
                              }}
                            />
                          )}
                          <Typography variant="body2">
                            {`${getCombinedCompletion(
                              task?.checklists
                            )}/${getTotalCheckboxes(task?.checklists)}`}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                  {task?.archived && (
                    <Box display="flex" alignItems="center">
                      <ArchiveIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                    </Box>
                  )}
                  {task?.comments?.filter((task) => task.type === "comment")
                    .length > 0 && (
                    <Box display="flex" alignItems="center">
                      <ChatBubbleIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {task?.comments?.filter(
                          (task) => task.type === "comment"
                        ).length + 1}
                      </Typography>
                    </Box>
                  )}

                  {task.attachments && task.attachments.length > 0 && (
                    <Box display="flex" alignItems="center">
                      <AttachFileIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {task.attachments.length}
                      </Typography>
                    </Box>
                  )}
                  {task.images && task.images.length > 0 && (
                    <Box display="flex" alignItems="center">
                      <ImageIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {task.images.length}
                      </Typography>
                    </Box>
                  )}
                  {task.links && task.links.length > 0 && (
                    <Box display="flex" alignItems="center">
                      <LinkIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {task.links.length}
                      </Typography>
                    </Box>
                  )}
                  {task.polls && convertToArray(task.polls)?.length && (
                    <Box display="flex" alignItems="center">
                      <PollIcon
                        fontSize="small"
                        sx={{
                          color: settings?.showHeatmap ? "black" : "#A0AD39",
                        }}
                      />
                      <Typography variant="body2">
                        {convertToArray(task.polls)?.length}
                      </Typography>
                    </Box>
                  )}
                  {activity && notifications.length > 0 && (
                    <Box display="flex" alignItems="center">
                      <CelebrationIcon color="error" />
                      <Typography variant="body2">
                        {notifications?.length}
                      </Typography>
                    </Box>
                  )}

                  {task?.dueDate && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        paddingX: "4px",
                        paddingY: "2px",
                        borderRadius: "5px",
                        backgroundColor: (() => {
                          const dueDate = new Date(task.dueDate);
                          const now = new Date();
                          const timeDifference = dueDate - now;
                          const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

                          if (timeDifference < 0) {
                            // Due date has passed
                            return "#FF6347"; // Red color for passed due date
                          } else if (timeDifference <= oneWeekInMillis) {
                            // Due date is within a week
                            return "#FFBF00"; // Amber color for upcoming due date
                          }
                          return "lightgrey"; // Default color if due date is not close or passed
                        })(),
                        color: "black",
                      }}
                    >
                      <DateRangeIcon fontSize="small" />
                      <Typography sx={{ fontSize: "13px", marginLeft: "2px" }}>
                        {new Date(task?.dueDate).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </Typography>
                    </Box>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {collaborators && (
                    <AvatarGroup max={3} spacing="medium">
                      {assignedMembers.map((member) => (
                        <Tooltip
                          title={
                            collaborators[member.uid]?.displayName ||
                            collaborators[member.uid]?.email
                          }
                          key={member.uid}
                        >
                          <Avatar
                            src={collaborators[member.uid]?.photoURL}
                            alt={collaborators[member.uid]?.displayName}
                            sx={{
                              width: "40px",
                              height: "40px",
                            }}
                          >
                            {collaborators[member.uid]?.displayName?.substring(
                              0,
                              1
                            ) ||
                              collaborators[member.uid]?.email?.substring(0, 1)}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  )}
                </div>
              </div>
            </Box>
          </Paper>
        </>
      )}

      <Modal open={open} onClose={handleClose}>
        <TaskModal
          collaborators={collaborators}
          boardId={boardId}
          columnId={columnId}
          taskId={taskId}
          onClose={handleClose}
          columnName={columnName}
          boardOwner={boardOwner}
          handleAssignMember={handleAssignMember}
          handleUnassignMember={handleUnassignMember}
          assignedMembers={assignedMembers}
          logAudit={logAudit}
          logToColumn={logToColumn}
          reverseComments={reverseComments}
          setReverseComments={setReverseComments}
          boardTitle={boardTitle}
          taskPath={taskPath}
          boardPath={boardPath}
          isLinked={isLinked}
          realTaskPath={realTaskPath}
          ownBoards={ownBoards}
          collaboratedBoards={collaboratedBoards}
        />
      </Modal>
    </>
  );
};

export default Task;

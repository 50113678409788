// TextRenderer.js

import React from "react";
import DOMPurify from "dompurify";

const TextRenderer = ({ text }) => {
  const renderText = (inputText) => {
    // Sanitize the input to remove any malicious code
    const sanitizedText = DOMPurify.sanitize(inputText, {
      ALLOWED_TAGS: ["span", "a", "br", "b", "i", "ul", "li"],
      ALLOWED_ATTR: ["href", "target", "rel", "class", "data-id"],
    });

    // Function to replace mentions and URLs
    const processContent = (html) => {
      // Create a DOM parser
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      // Replace mentions in the format @[DisplayName](email) with span elements
      const mentionPattern = /@\[(.+?)\]\((.+?)\)/g;

      doc.body.innerHTML = doc.body.innerHTML.replace(
        mentionPattern,
        (match, displayName, email) => {
          // Ensure there's an @ symbol in front
          const displayText = `${displayName}`;
          return `<span class="mention" data-id="${email}">@${displayText}</span>`;
        }
      );

      // Handle links (URLs)
      const urlPattern = /https?:\/\/[^\s]+/g;
      doc.body.innerHTML = doc.body.innerHTML.replace(urlPattern, (match) => {
        return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });

      return doc.body.innerHTML;
    };

    const processedContent = processContent(sanitizedText);

    return processedContent;
  };

  return (
    <div
      dangerouslySetInnerHTML={{ __html: renderText(text) }}
      style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
    />
  );
};

export default TextRenderer;

import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import { styled } from "@mui/material/styles";

const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    margin: 0; /* Remove margins */
    padding: 0; /* Remove padding */
  }
  .ProseMirror p {
    margin: 0; /* Remove margins from paragraphs */
  }
  .mention {
    background-color: #a0ad39;
    border-radius: 8px;
    padding: 3px 6px;
    color: white;
  }
`;

const CommentContentRenderer = ({ content }) => {
  const mentionExtension = Mention.configure({
    HTMLAttributes: {
      class: "mention",
    },
    parseHTML: [
      {
        tag: 'span[data-type="mention"]',
        getAttrs: (dom) => {
          const email = dom.getAttribute("data-id");
          const label = dom.getAttribute("data-label");
          return { id: email, label: label };
        },
      },
    ],
    renderHTML: ({ node, HTMLAttributes }) => {
      return [
        "span",
        {
          ...HTMLAttributes,
          "data-type": "mention",
          "data-id": node.attrs.id,
          "data-label": node.attrs.label,
          class: "mention",
        },
        `@${node.attrs.label}`, // Include '@'
      ];
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Mention.configure({
        mentionExtension,
        HTMLAttributes: {
          class: "mention",
        },
        parseHTML: [
          {
            tag: 'span[data-type="mention"]',
            getAttrs: (dom) => {
              const email = dom.getAttribute("data-id");
              const label = dom.getAttribute("data-label");
              return { id: email, label: label };
            },
          },
        ],
        renderHTML: ({ node, HTMLAttributes }) => {
          return [
            "span",
            {
              ...HTMLAttributes,
              "data-type": "mention",
              "data-id": node.attrs.id,
              "data-label": node.attrs.label,
              class: "mention",
            },
            `@${node.attrs.label}`,
          ];
        },
      }),
    ],
    editable: false,
    content: content,
  });

  return <StyledEditorContent editor={editor} />;
};

export default CommentContentRenderer;

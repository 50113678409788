import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  SquaredButtonGreen,
  TeldioCheckbox,
  TeldioOutlinedInput,
  TeldioSelect,
} from "../../../MUI_Components/Components";
import { getDB } from "../../../firebase/firebase";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { get, off, onValue, ref, update } from "firebase/database";
import { getTextColor } from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";

const FilterPanel = ({
  handleClose,
  collaborators,
  boardPath,
  boardOwner,
  labels,
  setFilteredMembers,
  filteredMembers,
  setFilteredLabels,
  filteredLabels,
}) => {
  const db = getDB();
  const { currentUser } = useAuth();
  const { settings } = useSettings();
  const themeDetails = useThemeContext();

  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    const members = typeof value === "string" ? value.split(",") : value;
    setFilteredMembers(members);
    handleToggleFilteredMembers(members);
  };

  const handleChangeLabels = (event) => {
    const {
      target: { value },
    } = event;
    const selectedLabels = typeof value === "string" ? value.split(",") : value;
    setFilteredLabels(selectedLabels);
    console.log(selectedLabels);
    handleToggleFilteredLabels(selectedLabels);
  };

  const handleClearAllFilters = () => {
    setFilteredLabels([]);
    setFilteredMembers([]);
    handleToggleFilteredLabels([]);
    handleToggleFilteredMembers([]);
  };

  const handleToggleFilteredMembers = async (members) => {
    try {
      let settingsRef;

      if (boardOwner === "own") {
        settingsRef = ref(db, boardPath + "/settings");
      } else {
        settingsRef = ref(db, boardPath + "/collaborators/" + currentUser.uid);
      }
      const settingsSnap = await get(settingsRef);
      let settings = settingsSnap.val() || {};

      settings.filteredMembers = members;

      if (boardOwner === "own") {
        await update(settingsRef, {
          filteredMembers: settings.filteredMembers,
        });
      } else {
        await update(ref(db, boardPath + "/collaborators/" + currentUser.uid), {
          filteredMembers: settings.filteredMembers,
        });
      }

      setFilteredMembers(settings.filteredMembers);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleToggleFilteredLabels = async (selectedLabels) => {
    try {
      let settingsRef;

      if (boardOwner === "own") {
        settingsRef = ref(db, boardPath + "/settings");
      } else {
        settingsRef = ref(db, boardPath + "/collaborators/" + currentUser.uid);
      }
      const settingsSnap = await get(settingsRef);
      let settings = settingsSnap.val() || {};

      settings.filteredLabels = selectedLabels;

      if (boardOwner === "own") {
        await update(settingsRef, { filteredLabels: settings.filteredLabels });
      } else {
        await update(ref(db, boardPath + "/collaborators/" + currentUser.uid), {
          filteredLabels: settings.filteredLabels,
        });
      }

      setFilteredLabels(settings.filteredLabels);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  function getUserDisplayNames(users, uids) {
    return uids
      .map((uid) => {
        const user = users.find((user) => user.uid === uid);
        return user ? user.displayName || user.email : null;
      })
      .filter(Boolean); // Filter out any null values in case there are UIDs without a corresponding user
  }

  return (
    <div
      style={{
        width: "300px",
        height: "auto",
        padding: "10px 10px 30px 10px",
        backgroundColor: themeDetails[settings.cardColor]?.primary || "white",
        color: themeDetails[settings.cardColor]?.font || "black",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <Typography variant="h5">Filter</Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <Typography variant="subtitle1">Members</Typography>
      <FormControl
        sx={{
          width: "100%",
          backgroundColor: themeDetails[settings.cardColor]?.primary || "white",
          color: themeDetails[settings.cardColor]?.font || "black",
        }}
      >
        <TeldioSelect
          inputProps={{
            "aria-label": "Without label",

            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor:
                    themeDetails[settings.cardColor]?.primary || "white",
                  color: themeDetails[settings.cardColor]?.font || "black",
                },
              },
            },
          }}
          placeholderColor={
            themeDetails[settings.cardColor]?.font || "darkgrey"
          }
          multiple
          displayEmpty
          value={filteredMembers}
          onChange={handleChangeMembers}
          input={<TeldioOutlinedInput />}
          renderValue={(selected) =>
            selected.length
              ? getUserDisplayNames(collaborators, selected).join(", ")
              : "None"
          }
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
        >
          {collaborators?.map((name) => (
            <MenuItem key={name.displayName || name.email} value={name.uid}>
              <TeldioCheckbox
                checked={filteredMembers.indexOf(name.uid) > -1}
              />
              <Avatar
                src={name.photoURL}
                alt={name.displayName}
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                }}
              />
              <ListItemText
                primary={
                  name.uid === currentUser.uid
                    ? "Me"
                    : name.displayName || name.email
                }
              />
            </MenuItem>
          ))}
        </TeldioSelect>
      </FormControl>

      <Typography variant="subtitle1">Labels</Typography>
      <FormControl sx={{ width: "100%" }}>
        <TeldioSelect
          inputProps={{
            "aria-label": "Without label",

            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor:
                    themeDetails[settings.cardColor]?.primary || "white",
                  color: themeDetails[settings.cardColor]?.font || "black",
                },
              },
            },
          }}
          multiple
          placeholderColor={
            themeDetails[settings.cardColor]?.font || "darkgrey"
          }
          displayEmpty
          value={filteredLabels}
          onChange={handleChangeLabels}
          input={<TeldioOutlinedInput />}
          renderValue={(selected) =>
            selected.length ? selected.join(", ") : "None"
          }
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
        >
          {labels?.map((label) => (
            <MenuItem key={label.name} value={label.name}>
              <TeldioCheckbox
                checked={filteredLabels.indexOf(label.name) > -1}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: label.color,
                  borderRadius: "10px",
                  color: getTextColor(label.color),
                  padding: "3px 3px 3px 10px",
                }}
              >
                <ListItemText primary={label.name} />
              </div>
            </MenuItem>
          ))}
        </TeldioSelect>
      </FormControl>
      <SquaredButtonGreen
        fullWidth
        sx={{ marginTop: "20px" }}
        onClick={handleClearAllFilters}
      >
        Clear All Filters
      </SquaredButtonGreen>
    </div>
  );
};

export default FilterPanel;

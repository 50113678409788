import React, { useRef, useState } from "react";
import {
  List,
  ListItem,
  Typography,
  IconButton,
  Box,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { ref, update } from "firebase/database";
import { getDB } from "../../../firebase/firebase";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import { useSettings } from "../../../Contexts/SettingsContext";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";
const Attachments = ({
  attachments,
  setAttachments,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskPath,
}) => {
  const storage = getStorage();
  const { currentUser } = useAuth();
  const db = getDB();
  const fileInputRef = useRef(null);
  const { settings } = useSettings();
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const themeDetails = useThemeContext();

  const handleFileUpload = (file) => {
    console.log(file);
    setUploading(true);

    const storageReference = storageRef(storage, `attachments/${file.name}`);
    uploadBytes(storageReference, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const newAttachment = {
          url,
          name: file.name,
          uploadedAt: new Date().toISOString(),
        };
        setAttachments((prev) => [...prev, newAttachment]);
        // Save attachments to database
        console.log(taskPath);
        const taskRef = ref(db, `${taskPath}`);
        update(taskRef, { attachments: [...attachments, newAttachment] });
        setUploading(false);
      });
    });
    logAudit(
      "Attachment added",
      `${file.name} has been attached`,
      `added attachment ${file.name}`
    );
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.items) {
      Array.from(event.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          handleFileUpload(file);
        }
      });
    }
  };

  return (
    <Box
      onDrop={handleFileDrop}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={() => setIsDragging(false)}
      sx={{
        border: isDragging ? "2px dashed #A0AD39" : "2px transparent",
        borderRadius: "15px",
        padding: "6px",
        width: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <UploadFileIcon
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          />
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Attachments
          </Typography>
        </Stack>
        <IconButton
          onClick={handleIconClick}
          aria-label="upload"
          sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
        >
          <UploadFileIcon />
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleFileUpload(e.target.files[0])}
        />
      </div>

      <List>
        {uploading && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CircularProgress sx={{ color: "#A0AD39" }} />
          </Box>
        )}
        {attachments.length === 0 && (
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Drag files over to upload..
          </Typography>
        )}
        {!uploading &&
          attachments.map((attachment, index) => (
            <ListItem
              key={index}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "60%" }}
              >
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: themeDetails[settings.cardColor]?.font || "black",
                    padding: 1,
                  }}
                  startIcon={<DownloadIcon />}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ wordBreak: "break-all", textAlign: "left" }}
                  >
                    {attachment.name}
                  </Typography>
                </Button>
              </a>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginRight: "10px", marginLeft: "10px" }}
                >
                  {new Date(attachment.uploadedAt).toLocaleString()}
                </Typography>
                <IconButton
                  sx={{
                    color: themeDetails[settings.cardColor]?.font || "black",
                  }}
                  onClick={() => {
                    deleteObject(
                      storageRef(storage, `attachments/${attachment.name}`)
                    );
                    setAttachments((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                    // Update database
                    const updatedAttachments = attachments.filter(
                      (_, i) => i !== index
                    );
                    const taskRef = ref(db, `${taskPath}`);
                    update(taskRef, { attachments: updatedAttachments });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default Attachments;

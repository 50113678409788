import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./firebase/contexts/AuthContext";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import SignIn from "./PreSignIn/Signin";
import Dashboard from "./Components/Dashboard/Dashboard";
import { SettingsProvider } from "./Contexts/SettingsContext";
import ForgotPassword from "./PreSignIn/ForgotPassword";
import ResetPassword from "./PreSignIn/ResetPassword";
import { ThemeDetailsProvider } from "./Contexts/ThemeDetailsContext";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <SettingsProvider>
                  <ThemeDetailsProvider>
                    <Dashboard />
                  </ThemeDetailsProvider>
                </SettingsProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

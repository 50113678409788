import { off, onValue, ref } from "firebase/database";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getDB } from "../firebase/firebase";
import { useAuth } from "../firebase/contexts/AuthContext";

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const db = getDB();
    const recentRef = ref(db, `/uid/${currentUser.uid}/settings`);
    const listener = onValue(
      recentRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setSettings(snapshot.val());
        } else {
          console.log("No data available");
        }
      },
      (error) => {
        console.error("Failed to read data:", error);
      }
    );

    return () => off(recentRef, "value", listener);
  }, [currentUser]);

  const updateSettings = (settingKey, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [settingKey]: value,
    }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

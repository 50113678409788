// MentionList.js

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { List, ListItemButton, Avatar, Typography } from "@mui/material";

const MentionList = forwardRef((props, ref) => {
  const { items, command } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  // Expose the onKeyDown method via ref using useImperativeHandle
  useImperativeHandle(ref, () => ({
    onKeyDown: (event) => {
      if (event.key === "ArrowDown") {
        setSelectedIndex((prevIndex) =>
          prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
        );
        event.preventDefault();
        return true;
      } else if (event.key === "ArrowUp") {
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        event.preventDefault();
        return true;
      } else if (event.key === "Enter") {
        command(items[selectedIndex]);
        event.preventDefault();
        return true;
      }
      return false;
    },
  }));

  return (
    <div>
      <List
        sx={{
          padding: 0,
          borderRadius: "4px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          maxHeight: "200px",
          overflowY: "auto",
        }}
      >
        {items?.map((item, index) => (
          <ListItemButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => command(item)}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            <Avatar
              src={item.avatar}
              alt={item.name}
              sx={{ width: 24, height: 24, marginRight: "10px" }}
            />
            <Typography variant="body2">{item.name}</Typography>
          </ListItemButton>
        ))}
      </List>
    </div>
  );
});

export default MentionList;

import {
  Card,
  IconButton,
  Typography,
  FormControl,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  SquaredButtonGreen,
  TeldioOutlinedInput,
  TeldioSelect,
} from "../../../MUI_Components/Components";
import { convertToArray } from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";
import { ref, get, set, push, remove } from "firebase/database";
import LogoutIcon from "@mui/icons-material/Logout";
import { getDB } from "../../../firebase/firebase";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";

const MovePopover = ({
  closePopover,
  ownBoards,
  collaboratedBoards,
  boardTitle,
  taskId,
  columnId,
}) => {
  const [selectedBoard, setSelectedBoard] = useState(boardTitle);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { settings } = useSettings();
  const { currentUser } = useAuth();
  const themeDetails = useThemeContext();

  const allBoards = useMemo(() => {
    const ownBoardsWithId = Object.entries(ownBoards).map(
      ([boardID, board]) => ({
        ...board,
        boardID,
        ownerUID: currentUser.uid,
      })
    );

    console.log("collaboratedBoards", collaboratedBoards);

    const collaboratedBoardsWithId = Object.entries(collaboratedBoards).map(
      ([boardID, board]) => ({
        ...board,
        boardID,
        ownerUID: board.owner,
      })
    );

    return [...ownBoardsWithId, ...collaboratedBoardsWithId];
  }, [ownBoards, collaboratedBoards]);

  const selectedBoardDetails = useMemo(
    () => allBoards.find((board) => board.title === selectedBoard),
    [selectedBoard, allBoards]
  );

  const availableColumns = useMemo(() => {
    const columns = convertToArray(selectedBoardDetails?.columns) || [];
    const columnsOrder = selectedBoardDetails?.columnsOrder || [];

    return columnsOrder
      .map((columnUid) => columns.find((col) => col.uid === columnUid))
      .filter(Boolean);
  }, [selectedBoardDetails]);

  const availableTasks = useMemo(() => {
    const column = availableColumns.find((col) => col.title === selectedColumn);
    return convertToArray(column?.tasks) || [];
  }, [availableColumns, selectedColumn]);

  const handleBoardChange = (event) => {
    setSelectedBoard(event.target.value);
    setSelectedColumn("");
    setSelectedIndex(null);
  };

  const handleMoveTask = async ({
    taskId,
    sourceBoardOwner,
    sourceBoardId,
    sourceColumnId,
    targetBoardId,
    targetColumnId,
    targetBoardOwner,
    targetColumnTitle,
    targetIndex,
    taskContents,
  }) => {
    const db = getDB();

    // Get references to the source and target paths
    const sourceTaskRef = ref(
      db,
      `/uid/${sourceBoardOwner}/boards/${sourceBoardId}/columns/${sourceColumnId}/tasks/${taskId}`
    );
    const sourceTaskOrderRef = ref(
      db,
      `/uid/${sourceBoardOwner}/boards/${sourceBoardId}/columns/${sourceColumnId}/taskOrder`
    );
    const targetTaskRef = ref(
      db,
      `/uid/${targetBoardOwner}/boards/${targetBoardId}/columns/${targetColumnId}/tasks/${taskId}`
    );
    const targetTaskOrderRef = ref(
      db,
      `/uid/${targetBoardOwner}/boards/${targetBoardId}/columns/${targetColumnId}/taskOrder`
    );

    // Fetch the source task order
    const sourceTaskOrderSnap = await get(sourceTaskOrderRef);
    const sourceTaskOrder = sourceTaskOrderSnap.val() || [];

    // If the source and target columns are the same, handle the reordering differently
    if (sourceBoardId === targetBoardId && sourceColumnId === targetColumnId) {
      const currentIndex = sourceTaskOrder.indexOf(taskId);

      // Remove the task from the current position
      sourceTaskOrder.splice(currentIndex, 1);

      // Insert the task at the new index
      sourceTaskOrder.splice(targetIndex, 0, taskId);

      // Update the task order
      await set(sourceTaskOrderRef, sourceTaskOrder);
    } else {
      // Handle the task move to a different column or board

      // Remove the task from the source column
      const updatedSourceTaskOrder = sourceTaskOrder.filter(
        (id) => id !== taskId
      );
      await set(sourceTaskOrderRef, updatedSourceTaskOrder);

      // Move the task to the target column
      await set(targetTaskRef, taskContents);

      // Update the task order in the target column
      const targetTaskOrderSnap = await get(targetTaskOrderRef);
      const targetTaskOrder = targetTaskOrderSnap.val() || [];
      targetTaskOrder.splice(targetIndex, 0, taskId);
      await set(targetTaskOrderRef, targetTaskOrder);

      // Clean up the old task data from the source column
      await remove(sourceTaskRef);
    }

    // Log the audit entry
    const auditRef = push(
      ref(
        db,
        `/uid/${targetBoardOwner}/boards/${targetBoardId}/columns/${targetColumnId}/tasks/${taskId}/audit`
      )
    );
    const newAudit = {
      action: "Task Moved",
      details: `Task "${taskContents.title}" moved to ${targetColumnTitle} by ${
        currentUser.displayName || currentUser.email
      }`,
      user: currentUser.uid,
      timestamp: Date.now(),
      processed: false,
      sender: currentUser.displayName || currentUser.email,
      senderUID: currentUser.uid,
    };
    await set(auditRef, newAudit);
  };

  const handleMoveTaskClick = async () => {
    if (selectedBoard && selectedColumn !== "" && selectedIndex !== null) {
      const sourceBoard = allBoards.find((board) => board.title === boardTitle);
      const targetBoard = allBoards.find(
        (board) => board.title === selectedBoard
      );
      const targetColumn = availableColumns.find(
        (col) => col.title === selectedColumn
      );
      if (targetBoard && targetColumn) {
        const db = getDB();
        const taskRef = ref(
          db,
          `/uid/${sourceBoard.ownerUID}/boards/${sourceBoard.boardID}/columns/${columnId}/tasks/${taskId}`
        );

        // Fetch task contents
        const taskSnap = await get(taskRef);
        const taskContents = taskSnap.val();
        console.log(taskContents);

        await handleMoveTask({
          taskId,
          sourceBoardId: sourceBoard.boardID,
          sourceColumnId: columnId,
          sourceBoardOwner: sourceBoard.ownerUID,
          targetBoardId: targetBoard.boardID,
          targetColumnId: targetColumn.uid,
          targetColumnTitle: targetColumn.title,
          targetBoardOwner: targetBoard.ownerUID,
          targetIndex: selectedIndex,
          taskContents,
        });

        // Close the popover after moving the task
        closePopover();
      }
    }
  };

  return (
    <Card
      sx={{
        width: "300px",
        padding: "0px 15px 15px 15px",
        backgroundColor: themeDetails[settings.cardColor]?.primary,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0 15px 5px",
        }}
      >
        <Typography variant="h6">Move Task</Typography>
        <IconButton onClick={closePopover}>
          <CloseIcon />
        </IconButton>
      </div>
      <FormControl
        sx={{
          width: "100%",
          backgroundColor: themeDetails[settings.cardColor]?.primary,
          color: themeDetails[settings.cardColor]?.font,
        }}
      >
        <TeldioSelect
          inputProps={{
            "aria-label": "Board Selection",
            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor: themeDetails[settings.cardColor]?.primary,
                  color: themeDetails[settings.cardColor]?.font,
                },
              },
            },
          }}
          placeholderColor={themeDetails[settings.cardColor]?.font}
          displayEmpty
          value={selectedBoard}
          onChange={handleBoardChange}
          input={<TeldioOutlinedInput />}
          renderValue={(selected) => selected || "Select a board"}
          size="small"
          sx={{ color: themeDetails[settings.cardColor]?.font }}
        >
          {allBoards.map((board) => (
            <MenuItem key={board.title} value={board.title}>
              <ListItemText primary={board.title} />
            </MenuItem>
          ))}
        </TeldioSelect>
      </FormControl>

      <div style={{ display: "flex", marginTop: "15px" }}>
        <FormControl
          sx={{
            width: "70%",
            backgroundColor: themeDetails[settings.cardColor]?.primary,
            color: themeDetails[settings.cardColor]?.font,
          }}
        >
          <TeldioSelect
            inputProps={{
              "aria-label": "Column Selection",
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor: themeDetails[settings.cardColor]?.primary,
                    color: themeDetails[settings.cardColor]?.font,
                  },
                },
              },
            }}
            placeholderColor={themeDetails[settings.cardColor]?.font}
            displayEmpty
            value={selectedColumn}
            onChange={(e) => {
              setSelectedColumn(e.target.value);
              setSelectedIndex(0);
            }}
            input={<TeldioOutlinedInput />}
            renderValue={(selected) => selected || "Select a column"}
            size="small"
            sx={{ color: themeDetails[settings.cardColor]?.font }}
          >
            {availableColumns.map((column) => (
              <MenuItem key={column.uid} value={column.title}>
                <ListItemText primary={column.title} />
              </MenuItem>
            ))}
          </TeldioSelect>
        </FormControl>

        <FormControl
          sx={{
            width: "30%",
            backgroundColor: themeDetails[settings.cardColor]?.primary,
            color: themeDetails[settings.cardColor]?.font,
            marginLeft: "10px",
          }}
        >
          <TeldioSelect
            inputProps={{
              "aria-label": "Task Index Selection",
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor: themeDetails[settings.cardColor]?.primary,
                    color: themeDetails[settings.cardColor]?.font,
                  },
                },
              },
            }}
            placeholderColor={themeDetails[settings.cardColor]?.font}
            displayEmpty
            value={selectedIndex}
            onChange={(e) => setSelectedIndex(e.target.value)}
            input={<TeldioOutlinedInput />}
            renderValue={(selected) =>
              selected !== null ? `#${selected + 1}` : "#"
            }
            size="small"
            sx={{ color: themeDetails[settings.cardColor]?.font }}
          >
            {availableTasks.map((_, index) => (
              <MenuItem key={index} value={index}>
                <ListItemText primary={`#${index + 1}`} />
              </MenuItem>
            ))}
          </TeldioSelect>
        </FormControl>
      </div>

      <div style={{ display: "flex", justifyContent: "right" }}>
        <SquaredButtonGreen
          sx={{ marginTop: "15px" }}
          onClick={handleMoveTaskClick}
          endIcon={<LogoutIcon />}
        >
          Move
        </SquaredButtonGreen>
      </div>
    </Card>
  );
};

export default MovePopover;

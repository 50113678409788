// ThemeDetailsContext.js

import React, { createContext, useContext } from "react";
import { useSettings } from "./SettingsContext";
import { getThemeDetails } from "../Helper/utils";

const ThemeDetailsContext = createContext();

export const useThemeContext = () => useContext(ThemeDetailsContext);

export const ThemeDetailsProvider = ({ children }) => {
  const { settings } = useSettings();
  const customColours = settings?.customColours || {};

  const themeDetails = getThemeDetails(customColours);

  return (
    <ThemeDetailsContext.Provider value={themeDetails}>
      {children}
    </ThemeDetailsContext.Provider>
  );
};

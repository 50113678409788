import {
  Avatar,
  Divider,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { convertToArray } from "../../../Helper/utils";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSettings } from "../../../Contexts/SettingsContext";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";
const History = ({
  taskTitle,
  collaborators,
  comments,
  setComments,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskName,
  boardTitle,
  taskPath,
  audit,
}) => {
  const [itemsToShow, setItemsToShow] = useState(2); // Initial number of items to show
  const [expanded, setExpanded] = useState(false); // Track whether the list is expanded
  const { settings } = useSettings();
  const themeDetails = useThemeContext();

  const DateTimeDisplay = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return <div>{formattedDate}</div>;
  };

  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 30); // Show 30 more items
    setExpanded(true); // Mark as expanded
  };

  const handleHide = () => {
    setItemsToShow(2); // Reset to showing only 2 items
    setExpanded(false); // Mark as not expanded
  };

  const parseMessage = (message) => {
    if (!message || message.length === 0) {
      return;
    }
    // Split the message by the <bold> and </bold> tags
    const parts = message?.split(/(<bold>|<\/bold>)/g);

    const parsedParts = [];

    // Loop through the parts and handle the tags correctly
    for (let i = 0; i < parts?.length; i++) {
      if (parts[i] === "<bold>") {
        // Add the next part in bold and skip the next index (since it has already been processed)
        parsedParts?.push(<strong key={i}>{parts[i + 1]}</strong>);
        i++; // Skip the content after <bold> as it's already processed
      } else if (parts[i] !== "</bold>") {
        // Add regular text for non-bold parts
        parsedParts?.push(parts[i]);
      }
    }

    return parsedParts;
  };
  return (
    <div style={{ marginTop: "20px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <StickyNote2Icon />
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            History
          </Typography>
        </Stack>

        <div>
          {itemsToShow < audit?.length && !expanded && (
            <IconButton onClick={handleShowMore}>
              <ExpandMoreIcon
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          )}
          {expanded && (
            <IconButton onClick={handleHide}>
              <ExpandLessIcon
                sx={{
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              />
            </IconButton>
          )}
        </div>
      </Stack>
      {audit?.slice(0, itemsToShow).map((item, index) => {
        if (!item?.senderUID) {
          return null;
        }
        let auditAuthor = convertToArray(collaborators).find(
          (user) => user.uid === item.senderUID
        );
        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: "5px",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Avatar
              src={auditAuthor?.photoURL}
              alt={auditAuthor?.displayName || auditAuthor?.email || "?"}
              style={{ marginRight: "10px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "bold", marginRight: "4px" }}>
                  {auditAuthor?.displayName ||
                    auditAuthor?.email ||
                    "Unknown User"}
                </span>
                {parseMessage(item.relativeDetails) || item.details}
              </Typography>
              <Typography sx={{ fontSize: "11px" }}>
                {DateTimeDisplay(item.timestamp)}
              </Typography>
              <Divider />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default History;
